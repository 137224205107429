import React from "react";
import { graphql, StaticQuery, withPrefix } from "gatsby"
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import { GatsbyImage } from "gatsby-plugin-image"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Studies = props => {
	const studies = props.data.allMarkdownRemark.edges;
	const intl = useIntl()
	return (
		<Layout bodyClass="page-services" path={props.location.pathname}>
			<SEO title={intl.formatMessage({ id: "Studies_title" })} />
			<div className="services-img w-100"></div>
			<div id="stduies_intro">
				<div className="container">
					<div className="row">
						<div className="col-12 my-2 px-3">
							<h1><FormattedMessage id="Studies_title" /></h1>
						</div>
					</div>
				</div>
			</div>

			<div className="container services services-tart pb-6">
				<div className="row">
					{studies.map(edge =>
						edge.node.frontmatter.lang == props.pageContext.intl.language ? (
							edge.node.frontmatter.studyImage ? (
								<div key={edge.node.frontmatter.path} className="col-6 mb-1">
									<Link to={edge.node.frontmatter.path}>
										<div className="card service p-1 service-teaser">
											<div className="card-content">
												<div className="row">
													<div className="col-md-3 card-kep rounded">
														<GatsbyImage image={edge.node.frontmatter.studyImage?.childImageSharp?.gatsbyImageData} />
													</div>
													<div className="col-md-9 card-tart pl-2">
														<h4>{edge.node.frontmatter.title}</h4>
														<p>{edge.node.frontmatter.description}</p>
													</div>
												</div>
											</div>
										</div>
									</Link>
								</div>
							) : (
								<div
									key={edge.node.frontmatter.path}
									className="col-12 col-md-6 mb-1"
								>
									<Link to={edge.node.frontmatter.path}>
										<div className="card service p-1 service-teaser">
											<div className="card-content">
												<h4>{edge.node.frontmatter.title}</h4>
												<p>{edge.node.frontmatter.description}</p>
											</div>
										</div>
									</Link>
								</div>
							)) : null
					)}
				</div>
			</div>
		</Layout>
	);
};

const studiesQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/studies/" }
        frontmatter: { active: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            path
            lang
            active
            description
            studyImage {
              publicURL
              childImageSharp {
						gatsbyImageData(layout: FIXED)
					}
            }
          }
        }
      }
    }
  }
`;

export default props => (
	<StaticQuery
		query={studiesQuery}
		render={data => (
			<Studies location={props.location} props data={data} {...props} />
		)}
	/>
)
